<template>
  <v-main>
    <v-app-bar dense flat color="#5bb4b4" dark>
      <v-spacer />
      <v-btn text href="https://tact-tokyo.co.jp/index.html">top</v-btn>
      <v-btn text href="https://tact-tokyo.co.jp/index.html">東京</v-btn>
      <v-btn text disabled>大阪</v-btn>
      <v-btn text disabled>名古屋</v-btn>
      <v-spacer />
    </v-app-bar>
    <v-container fluid>
      <v-row class="text-center">
        <v-col cols="12">サイトをリニューアルいたしました</v-col>
        <v-col cols="12">
          <b>5F</b>のご予約は
          <v-btn text color="blue" to="public/5F/new">こちら</v-btn>
          から
        </v-col>
        <v-col cols="12">
          <b>B1</b>のご予約は
          <v-btn text color="blue" to="public/B1/new">こちら</v-btn>
          から
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  data: () => ({}),
})
</script>
